<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">
            {{
              stu == "examination" ? "数据统计" : "班级列表"
            }}
            </a>
          <i>></i>
          <a href="javascript:;" @click="$router.back();">考试查询</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">答题详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <div class="searchbox changetreestyle">
            <div class="flexcb">
              <h3>考试成绩信息</h3>
              <el-button style="padding: 8px 20px" class="bgc-bv" @click="$router.back();">返回</el-button>
            </div>
            <div class="flexcb" style="padding: 20px 100px;" >
              <div>姓名： {{paperInfo.userName}}</div>
              <div><span>考试时间：{{paperInfo.submitTime}}</span></div>
              <div>考试IP :  {{ paperInfo.paperSubmitIp ? paperInfo.paperSubmitIp.split(",")[0] : '--'}}</div>
            </div>
            <div class="flexdc" style="
                align-items: flex-start;
                background: rgba(241, 247, 253, 1);
              ">
              <div style="padding: 20px" class="rowJsontitle">
                <div>
                  <img src="@/assets/paperSource.png" alt />
                  <span>
                    本次成绩：
                    <em style="color: #5c6be8; font-size: 20px">{{
                      rowJson.paperScore
                    }}</em>分
                  </span>
                </div>
                <div>
                  <img src="@/assets/success.png" alt />
                  <span>
                    正确题数：
                    <em style="color: #5c6be8; font-size: 20px">{{
                      rowJson.paperRight
                    }}</em>题
                  </span>
                </div>
                <div>
                  <img src="@/assets/error.png" alt />
                  <span>
                    错误题数：
                    <em style="color: #5c6be8; font-size: 20px">{{
                      rowJson.paperError
                    }}</em>题
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="framePage-scroll flexdc">
            <div class="bottom searchbox" style="padding-top: 0; padding-right: 1rem">
              <h3>试卷题目信息</h3>
            </div>
            <div class="ovy-a flex1" id="list-box">
              <div class="listExam" v-for="(item, index) in tableData" :key="index">
                <div class="list-item df">
                  <img src="@/assets/success.png" alt style="width: 16px; height: 16px; margin: 5px"
                    v-if="item.questionIsCorrect == true" />
                  <img src="@/assets/error.png" alt style="width: 16px; height: 16px; margin: 5px" v-else />
                  <span class="tixing" :class="{
                    tixing1: item.questionType == 0,
                    tixing2: item.questionType == 1,
                    tixing3: item.questionType == 2,
                    tixing4: item.questionType == 3,
                  }">{{
  $setDictionary("QUESTIONTYPE", item.questionType)
}}</span>
                  <div class="subject flex1">
                    <div class="subject-title">
                      <span>{{ indexMethod(index) }}、</span>
                      <span>{{ item.questionTitle }}</span>
                      <!-- <span class="number">(题目分值：{{ item.questionScore }})</span> -->
                    </div>
                    <div class="option" v-if="item.questionType == 0">
                      <el-radio-group v-model="item.questionMyAnswer" disabled class="radioGroup">
                        <el-radio label="A">A.{{ item.questionItem1 }}</el-radio>
                        <el-radio label="B">B.{{ item.questionItem2 }}</el-radio>
                        <el-radio label="C" v-if="item.questionItem3">C.{{ item.questionItem3 }}</el-radio>
                        <el-radio label="D" v-if="item.questionItem4">D.{{ item.questionItem4 }}</el-radio>
                        <el-radio label="E" v-if="item.questionItem5">E.{{ item.questionItem5 }}</el-radio>
                        <el-radio label="F" v-if="item.questionItem6">F.{{ item.questionItem6 }}</el-radio>
                      </el-radio-group>
                    </div>
                    <div class="option" v-if="item.questionType == 1">
                      <el-checkbox-group v-model="item.questionMyAnswerStr" class="radioGroup">
                        <el-checkbox label="A" disabled>A.{{ item.questionItem1 }}</el-checkbox>
                        <el-checkbox label="B" disabled>B.{{ item.questionItem2 }}</el-checkbox>
                        <el-checkbox label="C" disabled>C.{{ item.questionItem3 }}</el-checkbox>
                        <el-checkbox label="D" disabled v-if="item.questionItem4">D.{{ item.questionItem4 }}</el-checkbox>
                        <el-checkbox label="E" disabled v-if="item.questionItem5">E.{{ item.questionItem5 }}</el-checkbox>
                        <el-checkbox label="F" disabled v-if="item.questionItem6">F.{{ item.questionItem6 }}</el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <div class="option" v-if="item.questionType == 2">
                      <el-radio-group v-model="item.questionMyAnswer" disabled>
                        <el-radio label="T">是</el-radio>
                        <el-radio label="F">否</el-radio>
                      </el-radio-group>
                    </div>
                    <div class="option" v-if="item.questionType == 3">
                      <div class="analysis">{{ item.questionMyAnswer }}</div>
                    </div>
                    <div class="analysis">
                      正确答案：{{ item.questionAnswer }}
                    </div>
                    <div style="display: flex">
                      <p style="flex-shrink:0;">答案解析：</p>
                      <span class="analysis" v-html="item.questionAnalysis">{{
                        item.questionAnalysis
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getDatas" />
      </div>
    </div>
  </div>
</template>
<script>
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "operate/ExaminationDetails",
  components: {
    // Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      rowJson: {},
      stu: "",
      stu2: "",
      userId: "",
      paperInfo: {
        userName: "",
        submitTime: "",
        paperSubmitIp: "",
      }
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    this.stu2 = this.$route.query.stu2;
    this.userId = this.$route.query.userId;
  },
  mounted() { },
  computed: {},
  methods: {
    init() {
      this.stu = this.$route.query.stu;
      this.rowJson = JSON.parse(sessionStorage.getItem("rowJson"));
      this.getDatas();
      this.getPaperUser();
    },
    getPaperUser() {
      this.$post("/biz/paperUser/get", { paperUserId: this.rowJson.paperUserId })
          .then((ret) => {
            this.paperInfo = ret.data;
          });
    },
    getDatas(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperUserId: this.rowJson.paperUserId,
      };
      this.$post("/biz/paperUser/queryPaperUserQuestion", params).then(
        (ret) => {
          const retData = ret.data.list;
          retData.map((item) => {
            item.questionMyAnswerStr = item.questionMyAnswer.split("");
          });
          this.tableData = retData;
          this.apiData = {
            total: ret.data.total || 0,
            size: this.pageSize || 10,
            current: this.pageNum || 1,
          };
        }
      );
    },
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDatas();
    },
    indexMethod(index) {
      return (this.pageNum - 1) * this.pageSize + index + 1;
    },
    goback() {
      if (this.stu2 == "Yw") {
        this.$router.push({
          path: "/web/operate/ExaminationYw",
          query: {
            liuliuliu: true,
            userId: this.userId,
          },
        });
      } else if (this.stu == "examination" && this.stu2 != "Yw") {
        this.$router.push({
          path: "/web/operate/Examination",
          query: {
            liuliuliu: true,
          },
        });
      } else if (this.stu == "examinationJg" && this.stu2 == "Jg") {
        this.$router.push({
          path: "/web/ExaminationJg",
          query: {
            liuliuliu: true,
            userId: this.userId,
          },
        });
      }
    },
  },
};
</script>
<style lang="less">
.el-input__inner {
  line-height: 32px;
  height: 32px;
}

.listExam {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;

    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;

      .subject-title {
        display: flex;

        .number {
          padding-left: 1rem;
        }
      }

      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;

        .radioGroup {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;
          align-content: center;

          label {
            margin: 3px 0;
            display: flex;
            justify-content: flex-start;
          }

          .el-radio__label {
            width: 100%;
            word-wrap: break-word;
            word-break: break-all;
            white-space: pre-wrap;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.searchbox {
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    // &::before {
    //   content: "";
    //   border-left: 4px solid #4574f9;
    //   width: 4px;
    //   height: 16px;
    //   position: absolute;
    //   left: -17px;
    // }
  }
}

.rowJsontitle {
  padding: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-around;

  >div {
    display: flex;
    align-items: center;
    font-size: 18px;

    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}

.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}

.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}

.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}

.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}

.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}

.searchbox {

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}

.changetreestyle {
  .treeinput {
    .el-input {
      flex: 0;
    }
  }
}

.treeinput {
  .el-form-item__content {
    width: 36rem;
  }
}

.el-checkbox__inner {
  width: 18px;
  height: 18px;

  &:after {
    height: 10px;
    left: 6px;
  }
}
</style>
